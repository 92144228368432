/*
 * @Descripttion: 
 * @version: 
 * @Author: silva.yanhui
 * @Date: 2020-10-13 10:23:34
 * @LastEditors: silva.yanhui
 * @LastEditTime: 2020-10-26 15:31:06
 */
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'

import store from './store'
import 'nprogress/nprogress.css'
import router from './router'
/* plugins */
import 'jquery'
import Swiper, { Pagination, Navigation, Scrollbar, Keyboard, Thumbs } from 'swiper'
Swiper.use([Pagination, Navigation, Scrollbar, Keyboard, Thumbs])

import '../public/js/bootstrap.min.js'

import 'wowjs/css/libs/animate.css'

import 'swiper/swiper-bundle.css'
// svg 图标
import '@/assets/svg-icons'
import SvgIcon from './components/svg-icon/index.vue'// svg组件
import Loading from './components/Loading/index.vue'

const app = createApp(App).use(store).use(router)

// register globally
app.component('svg-icon', SvgIcon)
app.component('Loading', Loading)

app.mount('#app')
