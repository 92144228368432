/*
 * @Descripttion: 
 * @version: 
 * @Author: silva.yanhui
 * @Date: 2020-10-13 10:23:34
 * @LastEditors: silva.yanhui
 * @LastEditTime: 2020-10-29 09:21:11
 */
import { createWebHistory, createRouter, RouteRecordRaw } from 'vue-router'
import NProgress from 'nprogress'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: 'Welcome To Martian',
      subTitle: 'THE GREAT TRACK RECORD ON THE CHINESE MARKET'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Home/index.vue')
  },
  {
    path: '/games',
    name: 'Games',
    meta: {
      title: 'Games!',
      subTitle: ''
    },
    component: () => import(/* webpackChunkName: "games" */ '../views/Games/index.vue')
  },
  {
    path: '/hyper-casual',
    name: 'HYPER-CASUAL',
    meta: {
      title: 'HYPER-CASUAL',
      subTitle: ''
    },
    component: () => import(/* webpackChunkName: "hyperCasual" */ '../views/hyperCasual/index.vue')
  },
  {
    path: '/publish',
    name: 'Publish',
    meta: {
      title: 'Publish',
      subTitle: ''
    },
    component: () => import(/* webpackChunkName: "publish" */ '../views/Publish/index.vue')
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      title: ' About Us',
      subTitle: 'THE GREAT TRACK RECORD ON THE CHINESE MARKET'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About/index.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    meta: {
      title: ' Contact Us !',
      subTitle: ''
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Contact/index.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    meta: {
      title: 'Privacy Policy',
      subTitle: ''
    },
    component: () => import(/* webpackChunkName: "privacy-policy" */ '../views/PrivacyPolicy/index.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  NProgress.start()
  next()
})

router.afterEach(() => {
  NProgress.done()
})
export default router
